<template>
    <div>
        <div v-if="show">
            <div class="card" v-show="operation !== 'detail'">
                <div class="card-header card-header-flex pb-2 s-fixed-header">
                    <div class="w-100 mt-2">
                        <div class="row">
                            <div class="col-8">
                                <h5 class="mt-3 ml-0 mr-3 mb-2">
                                    <strong>
                                        <template v-if="operation === null">
                                            {{$t('title.orders')}}
                                            <span v-if="pagination.total">({{pagination.total}})</span>
                                        </template>
                                        <template v-else>{{ $t(operationTitle) }}</template>
                                    </strong>
                                </h5>
                            </div>
                            <div class="col-4 text-right">
                                <div v-if="operation === null">
                                    <div class="mt-0">
                                        <b-input-group class="mt-3">
                                            <b-form-input type="search" class="form-control form-control-sm"
                                                          :placeholder="$t('input.whatAreYouLookingFor')"
                                                          v-on:keyup.enter="handleSearch"
                                                          v-model="search">
                                            </b-form-input>
                                            <b-input-group-append>
                                                <b-button @click="setOperation('add')" variant="info" size="sm"
                                                          :title="$t('button.title.addNewItem')"
                                                          v-if="$global.hasRole('efl') || $global.hasRole('superadmin')"
                                                          v-b-tooltip.hover>
                                                    <i class="fe fe-plus"></i> {{$t('button.addNew')}}
                                                </b-button>
                                                <b-button @click="handleDealerToDealerLoadAddClick" variant="secondary"
                                                          size="sm" :title="$t('button.title.createSpotLoad')"
                                                          :disabled="selectedDealerToDealerOrderLength <= 0"
                                                          v-if="$global.hasPermission('ddloadsstore')"
                                                          v-b-tooltip.hover>
                                                    <i class="fe fe-plus"></i> {{$t('button.createDealerToDealerLoad')}}
                                                    <b-badge variant="dark">{{selectedDealerToDealerOrderLength}}</b-badge>
                                                </b-button>
                                                <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                          variant="outline-info"
                                                          @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                          v-if="$global.hasPermission('ordersview')">
                                                    <i class="fa fa-filter"></i>
                                                </b-button>
                                                <b-button size="sm" :title="$t('button.title.resetList')"
                                                          variant="outline-info"
                                                          @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                    <i class="fa fa-refresh"></i>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div v-else>
                                    <b-button variant="warning" size="sm" class="mt-0"
                                              @click="handleOperationClose()"
                                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                                        <i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
                                    </b-button>
                                </div>
                            </div>
                        </div><!-- /.row -->
                    </div><!-- /.w-100 -->
                </div>
                <div class="card-body">
                    <div class="orders-table">
                        <b-table hover responsive
                                 :busy="listingLoading"
                                 :items="dataSource"
                                 :fields="columns"
                                 :sort-by.sync="sortField"
                                 selectable
                                 ref="dealerToDealerTable"
                                 @row-clicked="handleRowClick"
                                 @sort-changed="handleSortChange">
                            <template v-slot:cell(brand_id)="{ detailsShowing, item, index, rowSelected }">
                                <template v-if="rowSelected">
                                    <span aria-hidden="true">&check;</span>
                                </template>
                                <a @click="toggleDetails(item)" class="d-inline in-center">
                                    <i :class="[{'fa fa-plus': !detailsShowing}, {'fa fa-minus': detailsShowing}]"></i>
                                </a>
                                <a-avatar shape="square" :size="32" icon="car"
                                          :title="item.brand ? item.brand.title : ''"
                                          v-if="item.brand.logo && item.brand.logo.download_url"
                                          :src="item.brand.logo.system_url+'/thumbnail/32x32/'+item.brand.logo.name2"/>
                                <a-avatar shape="square" :size="32" icon="car"
                                          :title="item.brand ? item.brand.title : ''" v-else/>
                            </template>
                            <template v-slot:cell(client_id)="record">
                                {{ (record.item.client ? record.item.client.company_name : '') }}
                            </template>
                            <template v-slot:cell(load_status)="{item}">
                                <ol-status :status="item.load_status"></ol-status>
                            </template>
                            <template v-slot:cell(from_location_id)="{item}">
                                <print-ad titled="1" :item="item.to_location"
                                          v-if="item.load_status == 8 || item.load_status == 9"></print-ad>
                                <print-ad titled="1" :item="item.from_location" v-else></print-ad>
                            </template>
                            <template v-slot:cell(dealer_location_id)="{item}">
                                <print-ad titled="1" :item="item.dealer_location"></print-ad>
                            </template>
                            <template v-slot:cell(dealer_id)="{item}">
                                {{(item.dealer || {}).name}}
                            </template>
                            <template v-slot:cell(model_id)="record">
                                <div>{{record.item.model ? record.item.model.title : ""}}</div>
                                <div>{{record.item.model ? record.item.model.version_code : ""}}</div>
                            </template>
                            <template v-slot:cell(updated_at)="record">
                                {{$global.utcDateToLocalDate(record.item.updated_at) }}
                            </template>
                            <template v-slot:cell(action)="{item}">
                                <a @click="setOperation('edit', item.id)"
                                   :title="$t('button.title.editItem')"
                                   v-if="item.load_status <= 1 && item.is_manual === 1"
                                   v-b-tooltip.hover>
                                    <i class="fe fe-edit"></i>
                                </a>
                                <!--                                <a @click="setOperation('detail', record.item.id)"-->
                                <!--                                   :title="$t('button.title.detailItem')" v-if="$global.hasPermission('ordersview')"-->
                                <!--                                   class="ml-2"-->
                                <!--                                   v-b-tooltip.hover>-->
                                <!--                                    <i class="icmn-info"></i>-->
                                <!--                                </a>-->
                            </template>
                            <template v-slot:row-details="{ item }">
                                <b-card>
                                    <b-list-group flush>
                                        <b-list-group-item><strong>{{$t('column.orderCode')}}</strong>:
                                            {{item.order_id}}
                                        </b-list-group-item>
                                        <b-list-group-item><strong>{{$t('column.comments')}}</strong>:
                                            {{item.comments}}
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-card>
                            </template>
                        </b-table><!-- /.b-table -->
                        <div class="clearfix">
                            <div class="float-left ">
                                <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]"
                                               size="sm"></b-form-select>
                            </div>
                            <div class="float-right">
                                <b-pagination
                                    v-model="pagination.current"
                                    :total-rows="pagination.total"
                                    :per-page="pagination.perPage"
                                    :first-text="$t('paginations.first')"
                                    :prev-text="$t('paginations.prev')"
                                    :next-text="$t('paginations.next')"
                                    :last-text="$t('paginations.last')"
                                ></b-pagination>
                            </div><!-- /.pull-right -->
                        </div><!-- /.clearfix -->
                    </div><!-- /.orders-table-->

                    <div class="orders-operation">
                        <a-drawer
                            placement="right"
                            :width="'950px'"
                            :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                            :closable="false"
                            @close="handleOperationClose"
                            :visible="operation !== null && operation !== 'detail'"
                            :zIndex="10"
                            :title="$t(operationTitle)"
                        >
                            <form @submit.prevent="handleSubmit" autocomplete="off">
                                <b-row>
                                    <b-col cols="12" v-show="global.pendingRequests > 0">
                                        <a-skeleton active :paragraph="{ rows: 5 }"/>
                                    </b-col>
                                    <b-col cols="12" v-show="global.pendingRequests <= 0">
                                        <b-row>
                                            <b-col sm="4">
                                                <div class="pull-right">
                                                    <quick-client-form
                                                        :after-create="handleAfterQuickClientCreated"
                                                        allow-create="1">
                                                    </quick-client-form>
                                                </div>
                                                <b-form-group
                                                    :label="$t('input.client')+' *'"
                                                    label-for="client_id"
                                                    :invalid-feedback="formErrors.first('client_id')">
                                                    <treeselect
                                                        :multiple="false"
                                                        :options="dropdowns.clients"
                                                        placeholder=""
                                                        v-model="formFields.client_id"
                                                        :class="[{'invalid is-invalid': (formErrors.has('client_id'))}]"
                                                    />
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="4">
                                                <div class="form-group">
                                                    <span class="pull-left">
                                                            <label for="brand" class="d-block">{{$t('input.brand')}} *</label>
                                                        </span><!-- /.pull-left -->
                                                    <span class="pull-right">
                                                        <quick-brand-form
                                                            allow-create="1"
                                                            :id="formFields.brand_id"
                                                            :after-create="handleAfterQuickBrandCreated">
                                                        </quick-brand-form>
                                                    </span><!-- /.pull-right -->
                                                </div>
                                                <treeselect
                                                    :multiple="false"
                                                    :options="dropdowns.brands"
                                                    placeholder=""
                                                    v-model="formFields.brand_id"
                                                    :class="[{'invalid is-invalid': (formErrors.has('brand_id'))}]"
                                                />
                                            </b-col><!--/b-col-->
                                            <b-col sm="4">
                                                <div class="clearfix">
                                                    <span class="pull-left">
                                                        <label for="brand" class="d-block">{{$t('input.models')}} *</label>
                                                    </span><!-- /.pull-left -->
                                                    <span class="pull-right">
                                                        <quick-model-form
                                                            :allow-create="(formFields.brand_id ? 1 : 0)"
                                                            :depend-brand-id="formFields.brand_id"
                                                            :after-create="handleAfterQuickModelCreated">
                                                        </quick-model-form>
                                                    </span><!-- /.pull-right -->
                                                </div><!-- /.clearfix -->
                                                <treeselect
                                                    :multiple="false"
                                                    :options="_.filter(dropdowns.models, (item) => item.brand_id === formFields.brand_id)"
                                                    placeholder=""
                                                    v-model="formFields.model_id"
                                                    :class="[{'invalid is-invalid': (formErrors.has('model_id'))}]"
                                                />
                                            </b-col><!--/b-col-->
                                            <b-col sm="6">
                                                <div class="form-group">
                                                    <div class="clearfix">
                                                        <span class="pull-left">
                                                            <label for="dealer_id" class="d-block">{{$t('input.fromDealer')}} *</label>
                                                        </span><!-- /.pull-left -->
                                                        <span class="pull-right">
                                                            <quick-dealer-form
                                                                    :allow-update="(formFields.load_status <= 0 ? 1 : 0)"
                                                                    :allow-create="(formFields.load_status <= 0 ? 1 : 0)"
                                                                    :id="formFields.dealer_id"
                                                                    :after-create="handleAfterQuickDealerCreated"
                                                                    :after-update="handleAfterQuickDealerUpdated">
                                                            </quick-dealer-form>
                                                        </span><!-- /.pull-right -->
                                                    </div><!-- /.clearfix -->
                                                    <treeselect
                                                            :multiple="false"
                                                            :options="dropdowns.dealers"
                                                            placeholder=""
                                                            :disabled="formFields.load_status > 1"
                                                            v-model="formFields.dealer_id"
                                                            :class="[{'invalid is-invalid': (formErrors.has('dealer_id'))}]"></treeselect>
                                                </div>
                                            </b-col><!--/b-col-->
                                            <b-col sm="6">
                                                <div class="form-group">
                                                    <div class="clearfix">
                                                        <span class="pull-left">
                                                            <label for="from_location_id" class="d-block">
                                                                {{$t('input.fromLocation')}} *
                                                            </label>
                                                        </span><!-- /.pull-left -->
                                                        <span class="pull-right">
                                                            <quick-location-form
                                                                allow-update="1"
                                                                allow-create="1"
                                                                :id="formFields.from_location_id"
                                                                :after-create="handleAfterQuickFromLocationCreated"
                                                                :after-update="handleAfterQuickFromAndToLocationUpdated">
                                                            </quick-location-form>
                                                        </span><!-- /.pull-right -->
                                                    </div><!-- /.clearfix -->
                                                    <treeselect
                                                        :multiple="false"
                                                        :options="dropdowns.locations"
                                                        placeholder=""
                                                        v-model="formFields.from_location_id"
                                                        :class="[{'invalid is-invalid': (formErrors.has('from_location_id'))}]"
                                                    />
                                                </div>
                                            </b-col><!--/b-col-->
                                            <b-col sm="6">
                                                <div class="form-group">
                                                    <div class="clearfix">
                                                        <span class="pull-left">
                                                            <label for="to_location_id" class="d-block">
                                                                {{$t('input.toDealer')}} *
                                                            </label>
                                                        </span><!-- /.pull-left -->
                                                        <span class="pull-right">
                                                            <quick-location-form
                                                                allow-update="1"
                                                                allow-create="1"
                                                                :id="formFields.to_location_id"
                                                                :after-create="handleAfterQuickToLocationCreated"
                                                                :after-update="handleAfterQuickFromAndToLocationUpdated">
                                                            </quick-location-form>
                                                        </span><!-- /.pull-right -->
                                                    </div><!-- /.clearfix -->
                                                    <treeselect
                                                        :multiple="false"
                                                        :options="dropdowns.locations"
                                                        placeholder=""
                                                        v-model="formFields.to_location_id"
                                                        :class="[{'invalid is-invalid': (formErrors.has('to_location_id'))}]"
                                                    />
                                                </div>
                                            </b-col><!--/b-col-->
                                            <b-col sm="6">
                                                <b-form-group
                                                    :label="$t('input.orderId')+ ' *'"
                                                    label-for="orderId"
                                                    :invalid-feedback="formErrors.first('order_id')"
                                                >
                                                    <b-form-input
                                                        id="order_id"
                                                        v-model="formFields.order_id"
                                                        type="text"
                                                        :state="((formErrors.has('order_id') ? false : null))"
                                                        @focus="$event.target.select()"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="6">
                                                <b-form-group
                                                    :label="$t('input.vinNumber')+ ' *'"
                                                    label-for="orderId"
                                                    :invalid-feedback="formErrors.first('vin_number')"
                                                >
                                                    <b-form-input
                                                        id="vin_number"
                                                        v-model="formFields.vin_number"
                                                        type="text"
                                                        :state="((formErrors.has('vin_number') ? false : null))"
                                                        @focus="$event.target.select()"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="6" v-show="0==1">
                                                <div class="form-group">
                                                    <div class="clearfix">
                                                        <span class="pull-left">
                                                            <label for="dealer_location_id" class="d-block">
                                                                {{$t('input.dealerLocation')}}
                                                            </label>
                                                        </span><!-- /.pull-left -->
                                                        <span class="pull-right">
                                                            <quick-location-form
                                                                    allow-update="1"
                                                                    allow-create="1"
                                                                    :id="formFields.dealer_location_id"
                                                                    :after-create="handleAfterQuickToDealerLocationCreated"
                                                                    :after-update="handleAfterQuickFromAndToLocationUpdated">
                                                            </quick-location-form>
                                                        </span><!-- /.pull-right -->
                                                    </div><!-- /.clearfix -->
                                                    <treeselect
                                                        :multiple="false"
                                                        :options="dropdowns.locations"
                                                        placeholder=""
                                                        v-model="formFields.dealer_location_id"
                                                        :disabled="formFields.load_status > 1"
                                                        :class="[{'invalid is-invalid': (formErrors.has('dealer_location_id'))}]"
                                                    />
                                                </div>
                                            </b-col><!--/b-col-->
                                        </b-row><!--/b-row-->
                                    </b-col><!--/b-col-->
                                    <div class="drawer-footer">
                                        <b-button
                                            size="sm"
                                            type="submit"
                                            variant="primary"
                                            :disabled="global.pendingRequests > 0"
                                            v-b-tooltip.hover :title="$t('button.title.save')"
                                        >
                                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                                         size="12px"
                                                         v-if="global.pendingRequests > 0"></clip-loader>
                                            <i class="fa fa-save mr-1"></i>
                                            {{$t('button.save')}}
                                        </b-button>
                                        <b-button variant="warning" class="ml-3"
                                                  size="sm" @click="handleOperationClose()"
                                                  v-b-tooltip.hover :title="$t('button.title.cancel')">
                                            <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                        </b-button>
                                    </div>
                                </b-row><!--/b-row-->
                            </form><!--/form-->
                        </a-drawer>
                    </div><!--/.orders-operation-->

                    <div class="filter-container">
                        <a-drawer
                            placement="left"
                            :width="'360px'"
                            :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                            :closable="false"
                            @close="filters.visible = !filters.visible"
                            :visible="!operation && filters.visible"
                            :zIndex="10"
                            :title="$t('title.advanceFilters')"
                        >
                            <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                                <b-row>
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.client')">
                                            <treeselect
                                                :multiple="true"
                                                :options="_.map(dropdowns.clients, (i) => ({...i, id: i.label}))"
                                                placeholder=""
                                                v-model="filters.clientByTitle"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.brands')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.brands"
                                                placeholder=""
                                                v-model="filters.brands"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.models')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.models"
                                                placeholder=""
                                                v-model="filters.models"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group :label="$t('input.fromAddedDate')">
                                            <b-form-datepicker placeholder="" v-model="filters.from_date"
                                                               class="mb-2"></b-form-datepicker>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group :label="$t('input.toAddedDate')">
                                            <b-form-datepicker placeholder="" v-model="filters.to_date"
                                                               class="mb-2"></b-form-datepicker>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                </b-row>
                                <div class="drawer-footer">
                                    <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                              class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                        {{$t('button.close')}}
                                    </b-button>
                                    <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                              :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                        {{$t('button.reset')}}
                                    </b-button>
                                    <b-button size='sm' variant="primary" button="submit" type="filled"
                                              :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                        {{$t('button.apply')}}
                                    </b-button>
                                </div><!-- /.drawer-footer -->
                            </form>
                        </a-drawer>
                    </div><!-- /.filter-container -->
                </div>
            </div><!-- /.row -->
            <div v-if="operation === 'detail'">
                <!--                <detail :handle-close-operation="handleOperationClose"></detail>-->
            </div>

            <!-- # Start DealerToDealer Load -->
            <a-drawer
                placement="right"
                :width="'86%'"
                :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                :closable="false"
                @close="handleDealerToDealerLoadFormOperationClose"
                :visible="dealerToDealerLoadFormVisible"
                :zIndex="10"
                :title="$t('title.createDealerToDealerLoad')"
            >
                <form @submit.prevent="() => handleDealerToDealerLoadSubmit('dealer/to/dealer/loads/create')"
                      autocomplete="off">
                    <b-row v-if="groupedDealerToDealerOrders.length > 0">
                        <b-col sm="12" v-for="(group, index) in groupedDealerToDealerOrders" :key="index">
                            <b-row>
                                <b-col sm="12" lg="2" md="2" class=" mb-sm-3">
                                    <h4 class="d-sm-inline">
                                    <span class="bg-gray-6 ml-2 rounded text-white p-1">
                                        {{$t('title.load')}}#<strong>{{index + 1}}</strong>
                                    </span>
                                    </h4>
                                    <h4 class="mt-4 ml-2 d-sm-inline">
                                    <span class="bg-light rounded p-1">
                                        <small>{{$t('title.orders')}}-{{group.items.length}}</small>
                                    </span>
                                    </h4>
                                </b-col>
                                <b-col sm="12" lg="3" md="3" class="ml-sm-1 mt-sm-2">
                                    <dl>
                                        <dt>{{$t('title.fromLocation')}}:</dt>
                                        <dd>
                                            <print-ad :item="group.from_location"></print-ad>
                                        </dd><!--/fromLocation-->
                                        <dt>{{$t('title.zone')}}:</dt>
                                        <dd>{{(group.from_zone || {}).zoneName}}</dd><!--/fromLocation-->
                                        <dt>{{$t('title.fromDealer')}}:</dt>
                                        <dd>{{(group.dealer || {}).name}}</dd><!--/dealer-->
                                        <dt>VIN:</dt>
                                        <dd>
                                            <div v-for="(order, index) in group.items">
                                                {{order.vin_number}}
                                            </div>
                                        </dd>
                                    </dl>
                                </b-col>
                                <b-col sm="12" lg="2" md="2" class="ml-sm-1 mt-sm-2">
                                    <dl>
                                        <dt>{{$t('title.brand')}}:</dt>
                                        <dd>{{group.brand.title}}</dd><!--/brand-->

                                        <dt>{{$t('title.model')}}:</dt>
                                        <dd>
                                            <div v-for="(order, index) in _.uniqBy(group.items, 'model.title')">
                                                {{order.model.title}}-{{order.model.version_code}}
                                            </div>
                                        </dd><!--/model-->

                                        <dt>{{$t('title.client')}}:</dt>
                                        <dd>{{group.client.company_name}}</dd><!--/model-->
                                    </dl>
                                </b-col>
                                <b-col sm="12" lg="4" md="4">
                                    <div>
                                        <small v-if="dealerToDealerLoadFormState.orders[index].errors" class="mb-2 d-block">
                                        <span class="text-danger"
                                              v-html="dealerToDealerLoadFormState.orders[index].errors"></span>
                                        </small>
                                    </div>

                                    <div class="form-group mb-2">
                                        <label class="d-block">{{$t('input.toLocation')}} *</label>
                                        <treeselect
                                                :multiple="false"
                                                :options="dropdowns.locations"
                                                placeholder=""
                                                v-model="dealerToDealerLoadFormState.orders[index].to_location_id"
                                                :class="[{'invalid is-invalid': (formErrors.has('to_location_id'))}]"
                                                @select="(value, instance) => handleToLocationChange(value, group, index)"
                                        />
                                        <div class="invalid-feedback">{{formErrors.first('to_location_id')}}</div>
                                    </div><!-- /.form-group -->

                                    <div class="form-group mb-2">
                                        <label class="d-block">{{$t('input.dealer')}} *</label>
                                        <treeselect
                                                :multiple="false"
                                                :options="dropdowns.dealers"
                                                placeholder=""
                                                v-model="dealerToDealerLoadFormState.orders[index].to_dealer_id"
                                                :class="[{'invalid is-invalid': (formErrors.has('to_dealer_id'))}]"
                                        />
                                        <div class="invalid-feedback">{{formErrors.first('to_dealer_id')}}</div>
                                    </div><!-- /.form-group -->

                                    <div class="form-group mb-2">
                                        <label class="d-block">{{$t('input.supplierCarrier')}} *</label>
                                        <treeselect
                                            :multiple="false"
                                            :options="group.suppliers"
                                            placeholder=""
                                            v-model="dealerToDealerLoadFormState.orders[index].supplier_carrier_id"
                                            :class="[{'invalid is-invalid': (formErrors.has('supplier_carrier_id'))}]"
                                            @input="(value, instance) => handleSupplierChange(value, group, index)"
                                        />
                                        <div class="invalid-feedback">{{formErrors.first('supplier_carrier_id')}}</div>
                                    </div><!-- /.form-group -->

                                    <div class="form-group mb-2">
                                        <dt>{{$t('title.zone')}}:</dt>
                                        <dd>{{(dealerToDealerLoadFormState.orders[index].to_zone || {}).zoneName}}</dd><!--/fromLocation-->
                                    </div>

                                    <div class="row mb-2">
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <b-form-checkbox v-model="dealerToDealerLoadFormState.orders[index].skip_dealer_confirmation" name="check-button">{{$t('input.skipDealerConfirmation')}}</b-form-checkbox>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <small v-if="dealerToDealerLoadFormState.orders[index].loading_factors > 0">
                                                {{$t('title.loadingFactors')}}:
                                                {{dealerToDealerLoadFormState.orders[index].loading_factors}}
                                            </small>
                                        </div>
                                        <div class="col-sm-12">
                                            <br>
                                            <small class="text-danger" v-if="dealerToDealerLoadFormState.orders[index].loading_factors > 0"
                                                   v-html="dealerToDealerLoadFormState.orders[index].loading_factor_note"></small>
                                            <small class="text-danger" v-if="!dealerToDealerLoadFormState.orders[index].price_id">
                                                {{ $t('msc.unableToDecidePrice') }}
                                            </small>
                                        </div>
                                    </div>

                                </b-col>
                                <b-col lg="12" md="12" sm="12" class="mt-3 mb-5">
                                    <b-card class="mb-0">
                                        <b-card-header v-b-toggle="'dealerToDealer-load-documents-'+index" class="p-0">
                                            <h4 class="mb-0">
                                            <span class="badge badge-primary">
                                                {{$t('title.attachments')}}
                                            </span>
                                                <small
                                                        v-show="dealerToDealerLoadFormState.orders[index].documents.length > 0">
                                                    - {{dealerToDealerLoadFormState.orders[index].documents.length}} Item/s
                                                </small>
                                            </h4>
                                        </b-card-header><!-- /.p-0-->
                                        <b-collapse :id="`dealerToDealer-load-documents-${index}`">
                                            <div class="bg-light p-3">
                                                <b-row>
                                                    <b-col lg="4" md="4" sm="12">
                                                        <div class="form-group">
                                                            <b-form-group
                                                                    :label="$t('input.title')+' *'"
                                                                    label-for="title"
                                                                    :invalid-feedback="formErrors.first('title')"
                                                            >
                                                                <b-form-input
                                                                        id="title"
                                                                        v-model="dealerToDealerLoadFormState.orders[index].document.title"
                                                                        type="text"
                                                                        :state="((formErrors.has('title') ? false : null))"
                                                                        @focus="$event.target.select()"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                            <div class="invalid-feedback">{{formErrors.first('title')}}
                                                            </div>
                                                        </div><!-- /.form-group -->
                                                    </b-col><!--/b-col-->
                                                    <b-col lg="4" md="4" sm="12">
                                                        <b-form-group class="mt-4 pt-2">
                                                            <upload
                                                                    v-model="dealerToDealerLoadFormState.orders[index].document.document"
                                                                    :disabled="dealerToDealerLoadFormState.orders[index].document.document"
                                                                    :title="(dealerToDealerLoadFormState.orders[index].document.document ? $t('msc.uploadedFile') : $t('msc.uploadFile'))"
                                                                    css-class="mt-0 btn-sm"></upload>
                                                            <b-button :title="$t('msc.removeUpload')"
                                                                      type="button"
                                                                      variant="outline-primary"
                                                                      v-b-tooltip.hover
                                                                      class="ml-2 ml-2 btn-sm"
                                                                      @click="() => {dealerToDealerLoadFormState.orders[index].document.document = null;}"
                                                                      :disabled="!dealerToDealerLoadFormState.orders[index].document.document"
                                                                      v-if="dealerToDealerLoadFormState.orders[index].document.document">
                                                                <i class="fa fa-close"></i>
                                                            </b-button>
                                                            <b-button :title="$t('button.download')"
                                                                      v-b-tooltip.hover
                                                                      type="button"
                                                                      variant="outline-primary"
                                                                      class="ml-2 ml-2 btn-sm"
                                                                      v-if="dealerToDealerLoadFormState.orders[index].document.document && dealerToDealerLoadFormState.orders[index].document.document.download_url"
                                                                      :disabled="!(dealerToDealerLoadFormState.orders[index].document.document && dealerToDealerLoadFormState.orders[index].document.document.download_url)"
                                                                      :href="(dealerToDealerLoadFormState.orders[index].document.document ? dealerToDealerLoadFormState.orders[index].document.document.download_url : '')"
                                                                      target="_blank">
                                                                <i class="fa fa-cloud-download"></i>
                                                            </b-button>
                                                        </b-form-group>
                                                        <div class="invalid-feedback d-block">
                                                            {{formErrors.first('document')}}
                                                        </div>
                                                    </b-col><!--/b-col-->
                                                    <b-col lg="2" md="2" sm="12">
                                                        <div class="form-group">
                                                            <label class="d-block">
                                                                <pre> </pre>
                                                            </label>
                                                            <b-button variant="info" type="button" size="sm"
                                                                      class="btn-block"
                                                                      @click="handleAddUpdateDealerToDealerLoadDocumentClick(index)">
                                                                <i class="fa fa-plus"></i>
                                                            </b-button><!--/b-button-->
                                                        </div><!-- /.form-group -->
                                                    </b-col><!--/b-col-->
                                                    <b-col lg="2" md="2" sm="12">
                                                        <div class="form-group">
                                                            <label class="d-block">
                                                                <pre> </pre>
                                                            </label>
                                                            <b-button variant="warning" type="button" size="sm"
                                                                      class="btn-block"
                                                                      @click="resetDealerToDealerLoadDocument(index)">
                                                                {{$t('button.reset')}}
                                                            </b-button><!--/b-button-->
                                                        </div><!-- /.form-group -->
                                                    </b-col><!--/b-col-->
                                                </b-row><!--/b-row-->
                                                <b-row>
                                                    <b-col cols="12">
                                                        <table class="table table-bordered bg-white">
                                                            <thead>
                                                            <tr>
                                                                <th width="50">#</th>
                                                                <th width="180">{{$t('column.title')}}</th>
                                                                <th width="60">{{$t('column.action')}}</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr v-for="(ct, i) in dealerToDealerLoadFormState.orders[index].documents"
                                                                :class="[{'table-primary': ct.token === dealerToDealerLoadFormState.orders[index].document.token}]">
                                                                <td>{{i + 1}}</td>
                                                                <td>
                                                                    {{ct.title}}
                                                                </td>
                                                                <td>
                                                                    <a @click="handleEditDealerToDealerLoadDocumentClick(index, ct.token)"
                                                                       :title="$t('button.title.editItem')"
                                                                       v-b-tooltip.hover>
                                                                        <i class="fe fe-edit"></i>
                                                                    </a>
                                                                    <a :title="$t('button.download')" class=" ml-1"
                                                                       :href="ct.document.download_url"
                                                                       target="_blank" v-b-tooltip.hover>
                                                                        <i class="fa fa-cloud-download"></i>
                                                                    </a>
                                                                    <a-popconfirm title="Are you sure？"
                                                                                  @confirm="handleDeleteDealerToDealerLoadDocumentClick(index, ct.token)">
                                                                        <i slot="icon" class="fe fe-trash"></i>
                                                                        <a class=" ml-1"
                                                                           :title="$t('button.title.deleteItem')"
                                                                           v-b-tooltip.hover>
                                                                            <i class="fe fe-trash"></i>
                                                                        </a>
                                                                    </a-popconfirm>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                            <tfoot
                                                                    v-show="dealerToDealerLoadFormState.orders[index].documents.length <= 0">
                                                            <tr>
                                                                <th colspan="3">{{$t('title.noDataAvailable')}}</th>
                                                            </tr>
                                                            </tfoot>
                                                        </table><!-- /.table table-bordered -->
                                                    </b-col><!--/b-col-->
                                                </b-row><!--/b-row-->
                                            </div><!-- /.bg-light -->
                                        </b-collapse><!-- /#dealerToDealer-load-documents-->
                                    </b-card><!-- /b-card -->
                                </b-col><!--/b-col-->
                            </b-row><!--/.row-->
                            <hr/>
                        </b-col><!--/.col-->
                    </b-row><!--/.row-->

                    <div class="drawer-footer">
                        <b-button
                                size="sm"
                                type="submit"
                                variant="primary"
                                :disabled="global.pendingRequests > 0"
                                v-b-tooltip.hover :title="$t('button.title.save')"
                        >
                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                         size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                            <i class="fa fa-save mr-1"></i>
                            {{$t('button.save')}}
                        </b-button>
                        <b-button variant="warning" class="ml-3"
                                  size="sm" @click="handleDealerToDealerLoadFormOperationClose()"
                                  v-b-tooltip.hover :title="$t('button.title.cancel')">
                            <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                        </b-button>
                    </div><!--/.drawer-footer-->
                </form><!--/form-->
            </a-drawer><!--/.drawer-->
        </div>
    </div><!--/div-->
</template>
<script>
    import Error from '../../../util/Error'
    import {mapState} from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import {request} from '../../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'
    import QuickLocationForm from "../../locations/QuickLocationForm"
    import moment from "moment-timezone"
    import ListingMixin from "../../../util/ListingMixin"
    import QuickClientForm from "../../clients/QuickClientForm";
    import QuickModelForm from "../../models/QuickModelForm";
    import QuickBrandForm from "../../brands/QuickBrandForm";
    import CreateDealerToDealerMixin from "./CreateDealerToDealerMixin";
    import QuickDealerForm from "../../dealers/QuickDealerForm";

    const FORM_STATE = {
        client_id: null,
        brand_id: null,
        model_id: null,
        from_location_id: null,
        to_location_id: null,
        dealer_id: null,
        dealer_location_id: null,
        order_id: null,
        vin_number: null,
        comments: null,
        status: null,
        _method: 'post',
        is_manual: 1,
        load_status: null,
        skip_dealer_confirmation: true,
    };

    const FILTER_STATE = {
        visible: false,
        from_date: null,
        to_date: null,
        brands: [],
        models: [],
        status: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17],
        is_manual: [1],
        has_manufacturer_invoice_date: 1,
        manufacturer_invoice_date: null,
        clientByTitle:[],
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: self.$t('column.brand'),
            key: 'brand_id',
            sortable: false,
            stickyColumn: true,
            class: 'w-120'
        },
        {
            label: self.$t('column.loadingPoint'),
            key: 'from_location_id',
            sortable: true,
            class: 'w-220'
        },
        {
            label: self.$t('column.client'),
            key: 'client_id',
            sortable: true,
            class: 'w-220'
        },
        {
            label: self.$t('column.dealer'),
            key: 'dealer_id',
            sortable: true,
            class: 'w-220'
        },
        {
            label: self.$t('column.dealerLocation'),
            key: 'dealer_location_id',
            sortable: true,
            class: 'w-220'
        },
        {
            label: self.$t('column.vin'),
            key: 'vin_number',
            sortable: true,
        },
        {
            label: self.$t('column.model'),
            key: 'model_id',
            sortable: true,
        },
        {
            label: self.$t('column.status'),
            key: 'load_status',
            sortable: true,
        },
        {
            label: self.$t('column.modifiedAt'),
            key: 'updated_at',
            sortable: true,
        },
        (self.$global.hasAnyPermission(['ordersupdate'])
            ? {
                label: self.$t('column.action'),
                class: 'text-right',
                key: 'action',
                width: 150,
            } : {}),
    ];

    export default {
        mixins: [ListingMixin, CreateDealerToDealerMixin],
        components: {
            Treeselect,
            QuickLocationForm,
            QuickClientForm,
            QuickModelForm,
            QuickBrandForm,
            QuickDealerForm
            // Detail,
        },
        data() {
            return {
                operationTitle: 'title.orders',
                formFields: {...FORM_STATE},
                formErrors: new Error({}),
                filters: {...FILTER_STATE},
                listUrl: 'orders',
                columns: COLUMN_DEFINITION(this),
                dropdowns: {
                    brands: [],
                    models: [],
                    supplierCarriers: [],
                    locations: [],
                    supervisors: [],
                    dealers: [],
                    zones: [],
                    status: [
                        {id: -3, label: this.$t('title.pending')},
                        {id: -2, label: this.$t('title.qualityHoldBlocked')},
                        {id: -1, label: this.$t('title.confirmationOrder')},
                        {id: 1, label: this.$t('title.produced')},
                        {id: 2, label: this.$t('title.addedToLoad')},
                        {id: 3, label: this.$t('title.assignedToLoad')},
                        {id: 4, label: this.$t('title.loaded')},
                        {id: 5, label: this.$t('title.inTransit')},
                        {id: 6, label: this.$t('title.unloadRequested')},
                        {id: 7, label: this.$t('title.unloaded')},
                        {id: 8, label: this.$t('title.stocked')},
                        {id: 9, label: this.$t('title.hold')},
                        {id: 10, label: this.$t('title.transportOrder')},
                        {id: 11, label: this.$t('title.announced')},
                        {id: 12, label: this.$t('title.rejected')},
                        {id: 13, label: this.$t('title.confirmed')},
                        {id: 14, label: this.$t('title.pickUp')},
                        {id: 15, label: this.$t('title.stockExitInTransit')},
                        {id: 16, label: this.$t('title.deliveryRequested')},
                        {id: 17, label: this.$t('title.delivered')},
                    ],
                    priorities: [
                        {id: 1, label: this.$t('title.high')},
                        {id: 2, label: this.$t('title.medium')},
                        {id: 3, label: this.$t('title.low')}
                    ],
                    clients:[],
                },
                show: true,
                dealerToDealerLoadFormVisible: false,
                dealerToDealerSelectedOrders: [],
                selectedDealerToDealerOrderLength: 0,
            }
        },
        mounted() {
            this.getBrands();
            this.getModels();
            this.getClients();
            this.getLocations();
            this.getSupervisors();
            this.getDealers();
            this.getZones();
        },
        methods: {
            randomString(length, chars) {
                let result = '';
                for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
                return result;
            },
            toggleDetails(row) {
                this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
            },
            handleRowClick(item, rowIndex, event) {
                item = {...item, to_location: item.from_location}
                this.handleDealerToDealerRowSelection(item, rowIndex, event);
            },
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = (operation === 'add' ? 'title.addOrder' : 'title.editOrder')
                this.operation = operation
                this.oToken = operationToken

                if (operation === 'edit') {
                    this.handleEditClick(operationToken)
                } else {
                    this.formFields.order_id = moment().format('MMYY') + this.randomString(2, 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ') + this.randomString(2, '0123456789')
                }
            },
            async handleSubmit() {
                this.formErrors = new Error({})
                try {
                    const response = await request({
                        url: this.formFields.id ? 'orders/update' : 'orders/create',
                        method: 'post',
                        data: {
                            ...this.formFields,
                            manufacturer_invoice_date: moment().format('YYYY-MM-DD'),
                            dealer_location_id: this.formFields.to_location_id
                        },
                    })

                    if (this.formFields.id) {
                        this.itemUpdated()
                    } else {
                        this.itemAdded()
                    }

                    this.handleOperationClose()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            async handleDeleteClick(id) {
                try {
                    const response = await request({
                        method: 'post',
                        url: '/orders/delete',
                        data: {
                            id: id,
                        },
                    })
                    this.loadList(this.listQueryParams)
                    this.itemDeleted()
                } catch (errors) {
                    this.itemDeleteFails()
                }
            },
            async handleEditClick(id) {
                try {
                    const response = await request({
                        method: 'get',
                        url: `/orders/detail/${id}`,
                    })
                    this.operationTitle = this.$t('title.editOrder')
                    const {data} = response
                    const {client, brand, model, from_location, to_location, dealer, dealer_location} = data
                    this.formFields = {
                        client_id: (client ? client.id : null),
                        brand_id: (brand ? brand.id : null),
                        model_id: (model ? model.id : null),
                        dealer_id: (dealer ? dealer.id : null),
                        dealer_location_id: (dealer_location ? dealer_location.id : null),
                        to_location_id: (to_location ? to_location.id : null),
                        from_location_id: (from_location ? from_location.id : null),
                        order_id: data.order_id,
                        vin_number: data.vin_number,
                        skip_dealer_confirmation: data.skip_dealer_confirmation > 0,
                        comments: null,
                        _method: 'post',
                        id: data.id,
                        load_status: data.load_status,
                        is_manual: 1
                    }
                } catch (e) {
                    this.itemEditFails()
                    this.formFields = {...FORM_STATE}
                }
            },
            async getBrands() {
                try {
                    const response = await request({
                        url: '/dropdowns/brands',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.brands = data

                } catch (e) {
                    this.dropdowns.brands = []
                }
            },
            async getModels() {
                try {
                    const response = await request({
                        url: '/dropdowns/models',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.models = data.map(item => ({...item, label: `${item.label} ${item.version_code}`}))
                } catch (e) {
                    this.dropdowns.models = []
                }
            },
            async getClients() {
                try {
                    const response = await request({
                        url: '/dropdowns/clients',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.clients = data

                } catch (e) {
                    this.dropdowns.clients = []
                }
            },
            async getLocations() {
                try {
                    const response = await request({
                        url: '/dropdowns/locations',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.locations = data.map(item => ({id: item.id, label: `${item.label}`, zip: item.zip}))
                } catch (e) {
                    this.dropdowns.locations = []
                }
            },
            async getSupervisors() {
                try {
                    const response = await request({
                        url: '/dropdowns/supervisors',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.supervisors = data

                } catch (e) {
                    this.dropdowns.supervisors = []
                }
            },
            async getDealers() {
                try {
                    const response = await request({
                        url: '/dropdowns/dealers',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.dealers = data
                } catch (e) {
                    this.dropdowns.dealers = []
                }
            },
            async getZones() {
                try {
                    const response = await request({
                        url: '/dropdowns/zones',
                        method: "post",
                        data: {
                            range: 1
                        }
                    })

                    const {data} = response
                    this.dropdowns.zones = data
                } catch (e) {
                    this.dropdowns.zones = []
                }
            },
            handleAfterQuickFromLocationCreated(inputs) {
                const newItem = {
                    id: inputs.id,
                    label: inputs.label
                }

                this.dropdowns.locations.push(newItem)
                this.formFields.from_location_id = inputs.id
            },
            handleAfterQuickToLocationCreated(inputs) {
                this.dropdowns.locations.push({
                    id: inputs.id,
                    label: inputs.label
                })
                this.formFields.to_location_id = inputs.id
            },
            handleAfterQuickToDealerLocationCreated(inputs) {
                this.dropdowns.locations.push({
                    id: inputs.id,
                    label: inputs.label
                })
                this.formFields.dealer_location_id = inputs.id
            },
            handleAfterQuickFromAndToLocationUpdated(inputs) {
                const index = _.findIndex(this.dropdowns.locations, {id: inputs.id})
                this.$set(this.dropdowns.locations[index], 'label', inputs.label)
            },
            hasListAccess() {
                return this.$global.hasPermission('ordersview')
            },
            refreshList() {
                this.loadList()
                this.$emit('refresh')
            },
            listLoaded() {
                this.handleDealerToDealerLoadFormOperationClose(false)
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            handleResetFilterClick() {
                this.filters = {...FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.handleResetClick()
                this.loadList(this.listQueryParams)
            },
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
                this.refreshList()
            },
            handleAfterQuickClientCreated(inputs) {
                const clients = [...this.dropdowns.clients, {id: inputs.id, label: inputs.company_name}];
                this.$set(this.dropdowns, 'clients', clients)
                this.formFields.client_id = inputs.id;
            },
            handleAfterQuickModelCreated(inputs) {
                const models = [...this.dropdowns.models, {
                    id: inputs.id,
                    label: inputs.title,
                    is_active: inputs.is_active,
                    brand_id: this.formFields.brand_id
                }];
                this.$set(this.dropdowns, 'models', models)
                this.dropdowns.models.push(inputs.id)
                this.formFields.model_id = inputs.id
            },
            handleAfterQuickBrandCreated(inputs) {
                const {id, title} = inputs
                this.dropdowns.brands.push({id: id, label: title})
                this.formFields.brand_id = id
            },
            handleAfterQuickDealerCreated(inputs) {
                const {id, name} = inputs
                this.dropdowns.dealers.push({id: id, label: name})
                this.formFields.dealer_id = id;
            },
            handleAfterQuickDealerUpdated(inputs) {
                const index = _.findIndex(this.dropdowns.dealers, {id: inputs.id})
                this.$set(this.dropdowns.dealers[index], 'label', inputs.name)
            },

        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
<style lang="scss">
    .w-80 {
        width: 80px;
    }

    .w-120 {
        width: 120px;
    }

    .w-220 {
        width: 220px;
    }

    .in-center {
        position: relative;
        top: 1px;
        margin-right: 8px;
    }

    @media screen and (max-width: 1379px) {
        .ant-drawer-content-wrapper {
            width: 98% !important;
        }
    }
</style>
